<script>
import appConfig from "../../../app.config";
import NavMenu from "../landing/inc/nav-menu.vue";
import Swal from "sweetalert2";

import Footer from "../landing/inc/footer.vue";

import ImgShop1 from "@/assets/images/shop/shop_home1.png";
import ImgShop2 from "@/assets/images/shop/shop_home2.png";
import ImgShop3 from "@/assets/images/shop/shop_home3.png";
import ImgShop4 from "@/assets/images/shop/shop_home4.png";

import ImgShopFeature4 from "@/assets/images/shop/shop-feature-responsive.png";

import ImgAdmin1 from "@/assets/images/shop/admin-capture-dashboard.png";
import ImgAdmin2 from "@/assets/images/shop/admin-capture-product.png";
import ImgAdmin3 from "@/assets/images/shop/admin-capture-formbuilder.png";
import ImgAdmin4 from "@/assets/images/shop/admin-capture-order.png";
import ImgAdmin5 from "@/assets/images/shop/admin-capture-accountingcost.png";
import ImgAdmin6 from "@/assets/images/shop/admin-capture-setting.png";

export default {
    page: {
        title: "인쇄쇼핑몰",
        meta: [{
            name: "printshop",
            content: appConfig.description
        }],
    },
    data() {
        return {
            title: "Starter",
            ImgShop1, ImgShop2, ImgShop3, ImgShop4,
            ImgShopFeature4,
            ImgAdmin1, ImgAdmin2, ImgAdmin3, ImgAdmin4, ImgAdmin5, ImgAdmin6,
            items: [{
                text: "Pages",
                href: "/",
            },
            {
                text: "Starter",
                active: true,
            },
            ],
            shopFeatureModalShow: false
        };
    },
    components: {
        NavMenu,
        Footer
    },
    methods: {
        shopFeatureShow(image) {

            Swal.fire({
                imageUrl: image,
                imageAlt: "A tall image",
                confirmButtonClass: "btn btn-primary w-xs mt-2",
                buttonsStyling: false,
                showCloseButton: true,
            });
    
        }
    }
};
</script>
<style>
.swal2-popup {
    width:82em !important;
}
</style>

<template>
    <div id="daon-shop" class="layout-wrapper">

        <nav-menu></nav-menu>

        <div class="bg-overlay bg-overlay-pattern"></div>

        <section class="section header">
            <div class="bg-overlay"></div>
        </section>

        <section class="section pb-0 hero-section" id="hero">
            <div class="bg-overlay bg-overlay-pattern"></div>
            <b-container>
                <b-row class="justify-content-center">
                    <b-col lg="8" sm="10">
                        <div class="text-center mt-lg-5 pt-5">
                            <h1 class="display-6 fw-semibold mb-3 lh-base">인쇄쇼핑몰 솔류션 <span class="text-success">다온샵</span>
                            </h1>
                            <p class="lead text-muted lh-base">인쇄 자동견적, 기획사 상품견적, 견적서 출력, 디자인파일 첨부, 웹하드, 시안교정확인 등</p>

                            <div class="d-flex gap-2 justify-content-center mt-4">
                                <router-link to="/pages/contactus" class="btn btn-primary" v-if="false">데모요청 <i class="ri-arrow-right-line align-middle ms-1"></i></router-link>
                                <router-link to="/pages/pricing" class="btn btn-danger">제작문의 <i class="ri-eye-line align-middle ms-1"></i></router-link>
                            </div>
                        </div>

                        <div class="mt-4 mt-sm-5 pt-sm-5 mb-sm-n5 demo-carousel">
                            <div class="demo-img-patten-top d-none d-sm-block">
                                <img src="@/assets/images/landing/img-pattern.png" class="d-block img-fluid" alt="...">
                            </div>
                            <div class="demo-img-patten-bottom d-none d-sm-block">
                                <img src="@/assets/images/landing/img-pattern.png" class="d-block img-fluid" alt="...">
                            </div>

                            <b-carousel :interval="2000" class="carousel slide carousel-fade">
                                <div class="carousel-inner shadow-lg p-2 bg-white rounded">
                                    <b-carousel-slide active :img-src="ImgShop1" v-on:click="shopFeatureShow(ImgShop1)"/>
                                    <b-carousel-slide :img-src="ImgShop2" v-on:click="shopFeatureShow(ImgShop2)" />
                                    <b-carousel-slide :img-src="ImgShop3" v-on:click="shopFeatureShow(ImgShop3)" />
                                </div>
                            </b-carousel>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
            <div class="position-absolute start-0 end-0 bottom-0 hero-shape-svg">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 1440 120">
                    <g mask="url(&quot;#SvgjsMask1003&quot;)" fill="none">
                        <path d="M 0,118 C 288,98.6 1152,40.4 1440,21L1440 140L0 140z">
                        </path>
                    </g>
                </svg>
            </div>
        </section>
        
        <!-- 별도견적, 대용량 업로더 및 웹하드 기능 제공, 작업가이드, 시안확인, 신용카드,간편결제, 주문관리, 샘플이미지주문, 생산공정추적, SMS/알림톡(옵션), 효육적인회원관리(등급,포인트,SMS알림 등) -->
        <section class="section bg-light" id="marketplace">
            <b-container>
                <b-row class="justify-content-center">
                    <b-col lg="8">
                        <div class="text-center mb-5">
                            <h2 class="mb-3 fw-semibold lh-base">다온샵만의 특별한 기능</h2>
                            <p class="text-muted mb-4">다온샵 솔류션의 특징적인 기능을 살펴보세요.</p>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="4" class="product-item artwork music games">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/shop/shop-feature-estimate.png" alt=""
                                    class="card-img-top explore-img" />
                                <div class="bg-overlay"></div>
                            </div>
                            <b-card-body>
                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">인쇄 자동견적시스템</router-link>
                                </h5>
                                <p class="text-muted mb-0">다양한 인쇄옵션으로 자동견적을 산출하세요.</p>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col lg="4" class="product-item artwork music games">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/shop/shop-feature-postpress.png" alt=""
                                    class="card-img-top explore-img" />
                                <div class="bg-overlay"></div>
                            </div>
                            <b-card-body>
                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">다양한 후가공 옵션</router-link>
                                </h5>
                                <p class="text-muted mb-0">다양한 인쇄옵션으로 자동견적을 산출하세요.</p>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col lg="4" class="product-item artwork music games">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/shop/shop-feature-design.png" alt=""
                                    class="card-img-top explore-img" />
                                <div class="bg-overlay"></div>
                            </div>
                            <b-card-body>
                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">선택적 디자인 옵션</router-link>
                                </h5>
                                <p class="text-muted mb-0">디자인옵션으로 디자인비를 자유롭게 책정하세요.</p>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col lg="4" class="product-item artwork music crypto-card">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img :src="ImgShopFeature4" alt="" class="card-img-top explore-img" />
                                <div class="bg-overlay"></div>
                                <div class="place-bid-btn">
                                    <b-link @click="shopFeatureShow(ImgShopFeature4)" class="btn btn-success"><i class="ri-search-line align-bottom me-1"></i>확대보기</b-link>
                                </div>
                            </div>
                            <b-card-body>
                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">반응형 홈페이지</router-link>
                                </h5>
                                <p class="text-muted mb-0">PC, 휴대폰, 태블릿 모든 장비에 최적화된 UI 제공</p>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col lg="4" class="product-item crypto-card 3d-style">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/shop/shop-feature-chat.png" alt="" class="card-img-top explore-img" />
                            </div>
                            <b-card-body>
                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">채팅시스템</router-link>
                                </h5>
                                <p class="text-muted mb-0">고객과 실시간으로 대화하세요.</p>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col lg="4" class="product-item crypto-card 3d-style" v-if="false">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/shop/shop-feature-chat.png" alt="" class="card-img-top explore-img" />
                            </div>
                            <b-card-body>
                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">제품 이미지 갤러리</router-link>
                                </h5>
                                <p class="text-muted mb-0">이미지갤러리, 동영상로도 제공</p>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col lg="4" class="product-item games music 3d-style" v-if="false">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/shop/shop-feature5.png" alt="" class="card-img-top explore-img" />
                                <div class="bg-overlay"></div>
                            </div>
                            <b-card-body>
                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">리뷰시스템</router-link>
                                </h5>
                                <p class="text-muted mb-0">차별화된 제품 리뷰시스템</p>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col lg="4" class="product-item artwork music crypto-card" v-if="false">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/shop/shop-feature6.png" alt="" class="card-img-top explore-img" />
                                <div class="bg-overlay"></div>
                            </div>
                            <b-card-body>
                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">쿠폰시스템</router-link>
                                </h5>
                                <p class="text-muted mb-0">쿠폰기능으로 이벤트를 진행하세요.</p>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col lg="4" class="product-item artwork music crypto-card" v-if="false">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/shop/shop-feature8.png" alt="" class="card-img-top explore-img" />
                                <div class="bg-overlay"></div>
                            </div>
                            <b-card-body>
                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">위시리스트</router-link>
                                </h5>
                                <p class="text-muted mb-0">자주 저장하는 견적상품을 담아두세요.</p>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col lg="4" class="product-item artwork music crypto-card" v-if="false">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/shop/shop-feature9.png" alt="" class="card-img-top explore-img" />
                                <div class="bg-overlay"></div>
                            </div>
                            <b-card-body>
                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">HOME 화면 관리시스템</router-link>
                                </h5>
                                <p class="text-muted mb-0">로그인 및 회원가입의 광고베너를 관리할 수 있습니다.</p>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col lg="4" class="product-item artwork music crypto-card" v-if="false">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/shop/shop-feature9.png" alt="" class="card-img-top explore-img" />
                                <div class="bg-overlay"></div>
                            </div>
                            <b-card-body>
                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">베너 관리시스템</router-link>
                                </h5>
                                <p class="text-muted mb-0">쇼핑몰의 배너관리</p>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col lg="4" class="product-item artwork music crypto-card" v-if="false">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/shop/shop-feature9.png" alt="" class="card-img-top explore-img" />
                                <div class="bg-overlay"></div>
                            </div>
                            <b-card-body>
                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">로그인/회원가입 광고베너 제공</router-link>
                                </h5>
                                <p class="text-muted mb-0">로그인 및 회원가입의 광고베너를 관리할 수 있습니다.</p>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </b-container>
            
        </section>



        <section class="section pb-0 hero-section" id="hero">
            <div class="bg-overlay bg-overlay-pattern"></div>
            <b-container>
                <b-row class="justify-content-center">
                    <b-col lg="8" sm="10">
                        <div class="text-center mt-lg-5 pt-5">
                            <h1 class="display-6 fw-semibold mb-3 lh-base">다온샵 <span class="text-success">관리자시스템</span>
                            </h1>
                            <p class="lead text-muted lh-base">인쇄 최적화 주문관리창, 견적폼빌더, 견적가관리시스템, 용지관리, 단가관리, 옵션관리, 코드관리, 생산관리</p>

                            <div class="d-flex gap-2 justify-content-center mt-4">
                                <b-link href="/pages/contactus" class="btn btn-primary">데모요청 <i class="ri-arrow-right-line align-middle ms-1"></i></b-link>
                                <router-link to="/pages/pricing" class="btn btn-danger">개발문의 <i class="ri-eye-line align-middle ms-1"></i></router-link>
                            </div>
                        </div>

                        <div class="mt-4 mt-sm-5 pt-sm-5 mb-sm-n5 demo-carousel">
                            <div class="demo-img-patten-top d-none d-sm-block">
                                <img src="@/assets/images/landing/img-pattern.png" class="d-block img-fluid" alt="...">
                            </div>
                            <div class="demo-img-patten-bottom d-none d-sm-block">
                                <img src="@/assets/images/landing/img-pattern.png" class="d-block img-fluid" alt="...">
                            </div>

                            <b-carousel :interval="2000" class="carousel slide carousel-fade">
                                <div class="carousel-inner shadow-lg p-2 bg-white rounded">
                                    <b-carousel-slide active :img-src="ImgAdmin1" />
                                    <b-carousel-slide :img-src="ImgAdmin2" />
                                    <b-carousel-slide :img-src="ImgAdmin3" />
                                    <b-carousel-slide :img-src="ImgAdmin4" />
                                    <b-carousel-slide :img-src="ImgAdmin5" />
                                    <b-carousel-slide :img-src="ImgAdmin6" />
                                </div>
                            </b-carousel>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
            <div class="position-absolute start-0 end-0 bottom-0 hero-shape-svg">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 1440 120">
                    <g mask="url(&quot;#SvgjsMask1003&quot;)" fill="none">
                        <path d="M 0,118 C 288,98.6 1152,40.4 1440,21L1440 140L0 140z">
                        </path>
                    </g>
                </svg>
            </div>
        </section>

        <section class="section bg-light" id="marketplace">
            <b-container>
                <b-row class="justify-content-center">
                    <b-col lg="8">
                        <div class="text-center mb-5">
                            <h2 class="mb-3 fw-semibold lh-base">강력한 기능의 관리자시스템</h2>
                            <p class="text-muted mb-4">다온샵 관리자만의 강력한 기능을 살펴보세요.</p>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="4" class="product-item artwork crypto-card 3d-style">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/shop/admin-feature-orderonpage.png" alt="" class="card-img-top explore-img" />
                                <div class="bg-overlay"></div>
                            </div>
                            <b-card-body>
                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">한 페이지 주문관리창</router-link>
                                </h5>
                                <p class="text-muted mb-0">주문내역, 상세내용, 공정진행상태, 고객메세지를 한 페이지에서 확인</p>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col lg="4" class="product-item music crypto-card games">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/shop/admin-feature-responsive.png" alt="" class="card-img-top explore-img" />
                            </div>
                            <b-card-body>
                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">반응형 인터페이스 관리자시스템</router-link>
                                </h5>
                                <p class="text-muted mb-0">언제 어디서나 다양한 디바이스에서 사이트관리 가능</p>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col lg="4" class="product-item artwork music games">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/shop/admin-feature-papermanager.png" alt="" class="card-img-top explore-img" />
                            </div>
                            <b-card-body>
                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">용지관리 시스템</router-link>
                                </h5>
                                <p class="text-muted mb-0">-종이품목관리, 고시가, 판매가, 종이설명<br>-인쇄견적 실시간 반영</p>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col lg="4" class="product-item crypto-card 3d-style">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/shop/admin-feature-printingcost.png" alt="" class="card-img-top explore-img" />
                            </div>
                            <b-card-body>
                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">인쇄 제작단가 관리시스템</router-link>
                                </h5>
                                <p class="text-muted mb-0">옵셋도당단가, 판출력단가, 제본단가, 디지털인쇄단가 등 각종단가관리 시스템</p>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col lg="4" class="product-item games music 3d-style">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/shop/admin-feature-postpresscost.png" alt="" class="card-img-top explore-img" />
                            </div>
                            <b-card-body>
                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">후가공 작업/외주단가 관리</router-link>
                                </h5>
                                <p class="text-muted mb-0">- 다양한 후가공 제작 및 외주단가 관리<br>- 인쇄견적 실시간 반영</p>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col lg="4" class="product-item artwork music crypto-card">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/shop/admin-feature-estimateoption.png" alt="" class="card-img-top explore-img" />
                            </div>
                            <b-card-body>
                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">견적 옵션관리 시스템</router-link>
                                </h5>
                                <p class="text-muted mb-0">- 다양한 견적옵션 추가,수정,삭제 옵션관리툴</p>
                                <p class="text-muted mb-0">- 견적폼 실시간 반영</p>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <Footer></Footer>
    </div>
</template>